
<template>
	<div class="form__file-input">
		<div
			v-if="current_asset_data_loading"
			class="form__file-listing-container"
		>
			Loading...
		</div>
		<div
			v-else-if="current_asset_data.length"
			class="form__file-listing-container"
		>
			<ul class="form__file-listing">
				<li
					v-for="asset, index in current_asset_data"
					:key="index"
				>
					<img
						v-if="asset.kind === 'image'"
						:src="asset.url"
						alt=""
					>
					<div
						v-else
						class="form__file-listing-placeholder"
					/>
					<div
						class="form__file-listing-fileinfo"
					>
						<p class="form__file-listing-filename">
							{{ asset.filename }}
						</p>
						<p class="form__file-listing-filesize">
							{{ formatFilesize( asset.size ) }}
						</p>
					</div>
					<button
						class="form__file-listing-remove"
						aria-label="Remove file"
						@click="removeFileAtIndex( index )"
					/>
				</li>
			</ul>
		</div>

		<button
			v-if="acceptMultipleFiles || current_asset_data.length === 0"
			class="form__file-uploader"
			@click="show_browse_modal = true"
		>
			<div class="form__file-icon icon icon--upload" />
			<p
				class="form__file-instruction"
			>
				<span class="form__file-instruction--light">
					<span class="form__file-browse">Browse</span>
				</span>
			</p>
		</button>
	</div>

	<input
		:id="inputId"
		ref="file_input"
		type="file"
		:autocomplete="autocomplete"
		class="invisible"
		:multiple="acceptMultipleFiles"
		:accept="acceptedFileTypes"
		:disabled="disabled"
		@input="beginUpload"
	>

	<ModalComponent
		heading="File browser"
		:show="show_browse_modal"
		@close-modal="show_browse_modal = false"
	>
		<div
			class="form__file-grid"
			:class="{ 'form__file-grid--loading': assets_data_loading }"
		>
			<div class="form__file-grid__loading-overlay" />
			<ul class="form__file-grid-list">
				<li
					v-for="asset in assets"
					:key="asset.id"
					class="form__file-grid-item"
				>
					<label>
						<img
							v-if="asset.kind === 'image'"
							:src="asset.url"
							alt=""
						>
						<div
							v-else
							class="form__file-grid-placeholder"
						>
							{{ asset.filename }}
						</div>
						<input
							v-if="acceptMultipleFiles"
							v-model="chosen_assets"
							type="checkbox"
							:value="asset.id"
						>
						<input
							v-else
							v-model="chosen_asset"
							type="radio"
							:value="asset.id"
						>
					</label>
				</li>
			</ul>
		</div>
		<PaginationComponent
			v-if="page_count > 1"
			:page-count="page_count"
			:current-page="current_page"
			@page-decrement="decrementPage"
			@page-increment="incrementPage"
			@page-change="changePage"
		/>
		<div class="flex flex--gap-small">
			<ButtonComponent
				label="Confirm"
				:disabled="( acceptMultipleFiles && chosen_assets.length === 0 ) || ( !acceptMultipleFiles && chosen_asset === null )"
				@click="confirmChosenAssets"
			/>
			<ButtonComponent
				label="Upload file"
				@click="openUploadUi"
			/>
			<ButtonComponent
				label="Cancel"
				type="outline"
				@click="show_browse_modal = false"
			/>
		</div>
	</ModalComponent>
</template>

<script>

import PaginationComponent from './PaginationComponent.vue';
import ButtonComponent from './ButtonComponent.vue';
import ModalComponent from './ModalComponent.vue';

import gql_query_assets from '../graphql/query/AllAssets.gql';
import gql_query_assets_by_id from '../graphql/query/AssetsById.gql';
import gql_mutation_asset from '../graphql/mutation/Asset.gql';

import {
	getFileAsBase64
} from '../../../helpers.js';

export default {
	components: {
		PaginationComponent,
		ButtonComponent,
		ModalComponent,
	},
	props: {
		modelValue: {
			required: false,
			type: Array,
			default: () => [],
		},
		inputId: {
			required: true,
			type: String
		},
		disabled: {
			required: false,
			type: Boolean,
			default: false
		},
		acceptMultipleFiles: {
			required: false,
			type: Boolean,
			default: false,
		},
		acceptedFileTypes: {
			required: false,
			type: String,
			default: '',
		},
		acceptedAssetKinds: {
			required: false,
			type: Array,
			default: () => [],
		},
		pageSize: {
			required: false,
			type: Number,
			default: 20,
		},
	},
	emits: [ 'update:modelValue' ],
	data() {
		return {
			current_asset_data: [],
			assets: [],
			chosen_asset: null, // Used if acceptMultipleFiles === false
			chosen_assets: [], // Used if acceptMultipleFiles === true
			page_count: 0,
			current_page: 0,

			show_browse_modal: false,

			assets_data_loading: false,
			current_asset_data_loading: false,
		};
	},
	watch: {
		modelValue( new_ids ) {
			this.current_asset_data = [];
			this.loadCurrentAssetData( new_ids );
		},
		show_browse_modal( new_value ) {
			if ( new_value ) {
				this.loadAssetData();
			}
		},
		current_page() {
			this.loadAssetData();
		},
	},
	mounted() {
		if ( this.modelValue ) {
			this.loadCurrentAssetData( this.modelValue );
		}
	},
	methods: {
		loadAssetData() {
			this.assets_data_loading = true;
			this.$craftGraphqlApiClient.query(
				gql_query_assets,
				{
					limit: this.pageSize,
					offset: this.current_page * this.pageSize,
					kind: this.acceptedAssetKinds,
				}
			).then( ( response ) => {
				this.assets = response.data.assets;
				this.page_count = Math.ceil(
					response.data.assetCount / this.pageSize
				);
				this.assets_data_loading = false;
			} );
		},
		loadCurrentAssetData( asset_ids ) {
			if ( asset_ids.length === 0 ) {
				return;
			}
			this.current_asset_data_loading = true;
			this.$craftGraphqlApiClient.query(
				gql_query_assets_by_id,
				{ ids: asset_ids }
			).then( ( response ) => {
				this.current_asset_data = response.data.assets;
				this.current_asset_data_loading = false;
			} );
		},
		openUploadUi() {
			this.$refs.file_input.click();
		},
		async beginUpload() {
			const files = this.$refs.file_input.files;
			if ( files.length === 0 ) {
				return;
			}
			this.assets_data_loading = true; // Looks better if this loading state activates here.
			const asset_upload_promises = [];
			for ( let i = 0; i < ( this.acceptMultipleFiles ? files.length : 1 ); i++ ) {
				const base64 = await getFileAsBase64( files[i] );
				if ( !base64 ) {
					continue;
				}
				asset_upload_promises.push(
					this.$craftGraphqlApiClient.query(
						gql_mutation_asset,
						{
							filename: files[i].name,
							fileData: base64,
						}
					)
				);
			}
			await Promise.all( asset_upload_promises ).then( responses => {
				let model_value = this.modelValue;
				// Sometimes modelValue might not be an array to begin with.
				if ( !Array.isArray( model_value ) ) {
					model_value = [];
				}
				if ( this.acceptMultipleFiles ) {
					this.chosen_assets = model_value.concat( responses.map(
						response => response.data.save_uploads_Asset.id
					) );
				} else {
					this.chosen_asset = responses[0].data.save_uploads_Asset.id;
				}
			} ).finally( () => {
				this.$refs.file_input.value = null;
				this.loadAssetData();
			} );
		},
		confirmChosenAssets() {
			if ( this.acceptMultipleFiles ) {
				this.$emit(
					'update:modelValue',
					this.chosen_assets
				);
			} else {
				this.$emit(
					'update:modelValue',
					[this.chosen_asset]
				);
			}
			this.show_browse_modal = false;
			this.chosen_assets = [];
			this.chosen_asset = null;
		},
		removeFileAtIndex( index ) {
			this.$emit(
				'update:modelValue',
				this.modelValue.toSpliced( index, 1 )
			);
		},
		formatFilesize( bytes ) {
			// Logic from https://www.php.net/manual/de/function.filesize.php
			const units = ['B', 'KB', 'MB', 'GB', 'TB'];
			bytes = Math.max( bytes, 0 );
			let pow = Math.floor( ( bytes ? Math.log( bytes ) : 0 ) / Math.log( 1024 ) );
			pow = Math.min( pow, units.length - 1 );
			bytes /= ( 1 << ( 10 * pow ) );
			return Math.round( bytes ).toString() + units[pow];
		},
		changePage( index ) {
			this.current_page = index;
		},
		decrementPage() {
			if ( this.current_page > 0 ) {
				this.current_page--;
			}
		},
		incrementPage() {
			if ( this.current_page < ( this.page_count - 1 ) ) {
				this.current_page++;
			}
		},
	},
};

</script>
